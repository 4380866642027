<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useAutoAnimate } from '@formkit/auto-animate/vue';

const { t } = useI18n();
const props = defineProps({
    value: {},
    label: String,
    withText: Boolean,
    errors: { type: Array, default: [] },
});
const emits = defineEmits(['update:value']);
const originalValue = ref(props.value);
const roundedRating = computed(() => {
    return Math.round(props.value * 2) / 2;
});
const startColor = 'text-pink';

const setValue = (newValue) => {
    originalValue.value = newValue;
    emits('update:value', newValue);
};

const [errorContainer] = useAutoAnimate();
</script>
<template>
    <div class="w-full mb-4 formkit-outer">
        <label for="" class="block mb-2 text-base font-medium leading-tight formkit-label">
            {{ label }}
        </label>
        <div class="flex items-stretch gap-1">
            <div
                @click="setValue(i)"
                @mouseenter="value = i"
                @mouseleave="value = originalValue"
                v-for="i in 5"
                :key="i"
                :class="startColor"
            >
                <span v-if="roundedRating == i - 0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12">
                        <path
                            id="star_half_FILL0_wght400_GRAD0_opsz48"
                            d="M10,7.135V12l2.055,1.56L11.23,10.96l1.89-1.23H10.855ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                            transform="translate(-4 -4)"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <span v-else-if="roundedRating >= i">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12">
                        <path
                            id="star_FILL0_wght400_GRAD0_opsz48"
                            d="M6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Z"
                            transform="translate(-4 -4)"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <span v-else-if="roundedRating < i">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 12 12">
                        <path
                            id="grade_FILL0_wght400_GRAD0_opsz48"
                            d="M10,10.345ZM6.3,16,7.69,11.44,4,8.8H8.56L10,4l1.44,4.8H16l-3.69,2.64L13.7,16,10,13.18Zm1.65-2.445L10,12l2.055,1.56L11.23,10.96l1.89-1.23H10.855L10,7.135l-.855,2.6H6.88l1.89,1.23Z"
                            transform="translate(-4 -4)"
                            fill="currentColor"
                        />
                    </svg>
                </span>
            </div>
        </div>
        <ul ref="errorContainer" v-if="errors.length > 0" class="p-0 mt-1 mb-0 list-none formkit-messages">
            <li
                v-for="error in errors"
                :key="error"
                class="flex items-center gap-2 mb-1 ml-2 text-xs text-red-600 formkit-message"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.818" viewBox="0 0 16 13.818">
                    <path
                        id="warning_FILL0_wght400_GRAD0_opsz48"
                        d="M10.018,11.436ZM2,17.818,10,4l8,13.818Zm7.527-4.145h1.091V9.6H9.527Zm.545,2.018a.529.529,0,1,0-.391-.155A.53.53,0,0,0,10.073,15.691ZM3.891,16.727H16.109L10,6.182Z"
                        transform="translate(-2 -4)"
                        fill="#c30000"
                    />
                </svg>
                <span>{{ error }}</span>
            </li>
        </ul>
    </div>
</template>
